<template>
  <div>
    <div v-if="$userInfo.isSupportUser || $userInfo.isCustomerAdministrator"
      class="tile is-parent pl-1">
      <article class="tile is-child box">
        <div class="columns is-flex is-align-items-center">
          <div class="column">
            <p class="title">Item Category</p>
          </div>
          <div v-if="!hasError && showSavingStatus && !toastNotificationEnabled"
            class="column is-flex is-justify-content-flex-end is-align-items-center">
            <span v-if="saving"
              class="button is-loading is-white"
              style="height: 27px;" />
            <span v-else
              class="text has-text-success">
              <i class="mdi mdi-checkbox-marked-circle-outline mdi-18px" />
            </span>
            <span class="is-size-6"
              :class="saving ? 'has-text-warning' : 'has-text-success'">{{ savingStatus }}</span>
          </div>
        </div>

        <div class="category-title mb-2 mt-5">
          <div class="columns">
            <div class="column is-one-quarter">
              <label class="label pl-1">Category Name</label>
            </div>
            <div class="column is-one-quarter">
              <label class="label">Display Name</label>
            </div>
            <div class="column is-one-quarter">
              <label class="label">Print Name</label>
            </div>
            <div class="column is-one-quarter is-flex is-justify-content-center">
              <label class="label">Enabled</label>
            </div>
          </div>
        </div>

        <div v-for="(companyItemCategory, index) in companyItemCategories"
          :key="index"
          class="item-category-row">
          <div class="field columns is-flex is-align-items-center">
            <div class="column is-one-quarter pl-5">
              <div>{{ companyItemCategory.categoryName }}</div>
            </div>
            <div class="column is-one-quarter">
              <input v-model="companyItemCategory.displayName"
                type="text"
                class="input">
            </div>
            <div class="column is-one-quarter">
              <input v-model="companyItemCategory.printName"
                type="text"
                class="input">
            </div>
            <div class="column is-one-quarter is-flex is-justify-content-center">
              <div class="pretty p-icon p-curve p-smooth m-0 p-bigger is-two-third">
                <input v-model="companyItemCategory.isEnabled"
                  type="checkbox">
                <div class="state p-info">
                  <i class="icon mdi mdi-check" />
                  <label />
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
    <div v-else
      class="is-flex is-justify-content-center pt-5 ">
      <div class="notification is-danger is-flex is-justify-content-center is-size-4"
        style="width: 50%">
        You do not have permissions on this page
      </div>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import StoreMixin from './storeMixin'
import { DeepDiff } from 'deep-diff'

export default {
  name: 'ItemCategorySetting',
  mixins: [StoreMixin],
  data() {
    return {
      companyItemCategories: {}
    }
  },
  watch: {
    companyItemCategories: {
      handler: function(val) {
        this.saveSnapshot({ entity: _cloneDeep(val) })
        this.autoUpdate(val)
      },
      deep: true
    }
  },
  async created() {
    this.$showSpinner()
    this.initializeAutoUpdate()
    this.companyItemCategories = _cloneDeep(await this.getStoreItem({ serviceFunction: 'getCompanyItemCategories' }))
    this.$hideSpinner()
  },
  methods: {
    disableAutoUpdate() {
      if (typeof this.autoUpdate === 'function'
        && typeof this.autoUpdate.cancel === 'function') {
        this.autoUpdate.cancel()
      }
      this.autoUpdate = () => {}
    },
    initializeAutoUpdate() {
      this.autoUpdate = _debounce(async (value) => {
        this.snapshotDiff && await this.updateItemCategories(value)
      }, this.debounceDelay)
    },
    async updateItemCategories(itemCategories) {
      if (!this.snapshotDiff) return
      for (let item of itemCategories) {
        const [baseItem] = this.baseSnapshot.filter(i => i.itemCategoryId == item.itemCategoryId)
        const diff = DeepDiff.diff(baseItem, item)
        item.deepDiff = diff ? JSON.stringify(diff) : null
      }
      this.companyItemCategories = _cloneDeep(await this.editStoreItem({
        serviceFunction: 'updateCompanyItemCategories', entity: itemCategories }))

      if (this.toastNotificationEnabled) {
        this.openToastNotification()
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.disableAutoUpdate()
    this.toastNotificationEnabled = true
    await this.updateItemCategories(this.companyItemCategories)
    this.clearSnapshots()
    this.toastNotificationEnabled = false
    next()
  }
}
</script>

<style lang="scss" scoped>
input {
  width: 70%;
  height: 30px;
}

.category-title {
  background: #bedbfc;
}

.item-category-row {
  border-bottom: 1px solid #DBDBDB;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
</style>
